<script>
import { onMounted, onUnmounted, watch, ref, reactive, computed } from 'vue'
import { http } from '@/http'
import { setScrollTop, getScrollTop } from '@/utils'
import { needLoading, loading } from '@/reactive'
import useScrollData from '@/reactive/useScrollData'

export default {
  setup() {
    let inviteBrokerRecord = reactive({})
    let apiUrl = '/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.listInviteBroker'
    let { pageNum, tabType, getData, handleScroll,handleWatchData, dataList, dataListTemp, scrollElement } = useScrollData()

    function statisInviteBroker() {
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.statisInviteBroker')
        .then(res => {
          if(res.result) {
            for(let item in res.result) {
              inviteBrokerRecord[item] = res.result[item]
            }
            
          }
        })
    }

    handleWatchData(() => tabType.value, (brokerType) => {
      getData(apiUrl, {
        brokerType
      })
    })
    
    onMounted(() => {
      statisInviteBroker()
      getData(apiUrl,{
        brokerType: tabType.value
      })
      handleScroll(() => {
        getData(apiUrl, {
          brokerType: tabType.value
        })
      })
    })

    let newAll = computed(() => {
      if(inviteBrokerRecord.all > 99) {
        return '99+'
      }
      return inviteBrokerRecord.all
    })
    let newNotopen = computed(() => {
      if(inviteBrokerRecord.notOpen > 99) {
        return '99+'
      }
      return inviteBrokerRecord.notOpen
    })
    let newOpen = computed(() => {
      if(inviteBrokerRecord.open > 99) {
        return '99+'
      }
      return inviteBrokerRecord.open
    })
    let newRefund = computed(() => {
      if(inviteBrokerRecord.refund > 99) {
        return '99+'
      }
      return inviteBrokerRecord.refund
    })

    return {
      tabType,
      dataList, 
      dataListTemp,
      scrollElement,
      newAll,
      newRefund,
      newOpen,
      newNotopen,
      // inviteBrokerRecord,
      loading
    }
  }

}
</script>
<template>
  <div style="padding-bottom:10px">
    <!-- <div class="page-title">
      <h3 >邀请记录</h3>
    </div> -->
    <div style=" position:relative; height: 45px; margin-bottom:12px">
      <div class="tab">
        <span @click="tabType = ''" :class="{active: tabType === ''}" >全部({{newAll}})</span>
        <span @click="tabType = 'NOT_OPEN'" :class="{active: tabType === 'NOT_OPEN'}">未开通({{newNotopen}})</span>
        <span @click="tabType = 'OPEN'" :class="{active: tabType === 'OPEN'}">已开通({{newOpen}})</span>
        <span @click="tabType = 'REFUND'" :class="{active: tabType === 'REFUND'}">已退款({{newRefund}})</span>
      </div>
    </div>

    <div class="r-box" ref="scrollElement">
      <ul v-if="dataList.length">
        <li class="income-item" v-for="(item, idx) in dataList" :key="idx">
          <div class="cell">
            <p>{{item.status}}{{item.pay ? '-' : ''}}{{item.pay}}</p>
            <p>{{item.name}}</p>
          </div>
          <div class="cell">{{item.openTime}}</div>
        </li>
      </ul>
      <p v-if="loading === false && !dataListTemp.length" style="padding-top: 15px;text-align: center; font-size:.28rem">暂无数据 :(</p>
    </div>
  </div>

</template>

<style scoped>
  .top-bg {overflow: hidden; padding: 0 14px;  margin-bottom: -4rem; color: #fff; height: 130.24rem; background-size: 100% 100%; background-image:url('~@/assets/images/bg-rd-top.png') }
  .top-bg p { font-size: 0.24rem }
  .price { margin-top: 1.53125rem; display: inline-block; font-weight: 700; font-size:1.6rem }
  
  .tab { padding:15px 0;  background: #fff;position: fixed; top: 0; display:table; width:100% }
  .tab span { display:table-cell; font-size: .28rem; text-align: center}
  .tab span.active { color: #00abeb }
  .income-item { display: table; width: 100%; box-sizing: border-box;font-size: 0.28rem; padding-top: 15px; border-bottom:1px solid #f1f1f1 }
  .income-item span { display: inline-block;color:#999 }
  .income-item .cell { display:table-cell }
  .income-item .cell:last-child {color: #999; text-align: right; font-size: 0.24rem;vertical-align:middle }
  .income-item p { margin-bottom:14px }
  .income-item p:last-child {  color: #999;margin-bottom:14px }
</style>